<template>
  <div>
    <action-header
        :is-loading="!user"
        :actions-model="headerModel"
        :page="page"
    />

    <div class="q-pa-md">
      <q-legend :label="$t('Userdata')"/>

      <form-builder :schema="schema"/>
    </div>

    <div class="text-center">
      <div v-if="user && user.id" class="q-pa-md">
        <shops-table
            :hide-actions="true"
            :options="options"
            class="q-mb-sm"
        />

        <warehouses-table
            :hide-actions="true"
            :options="options"
            class="q-mb-sm"
        />

        <senders-table
            :hide-actions="true"
            :options="options"
            class="q-mb-sm"
        />

        <accounts-table
            :hide-actions="true"
            :options="options"
            class="q-mb-sm"
        />

        <legal-entities-table
            :hide-actions="true"
            :options="options"
            class="q-mb-sm"
        />

        <connections-table
            :hide-actions="true"
            :options="options"
            :user="user"
            class="q-mb-sm"
        />
      </div>
    </div>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="hasChange"
        :disabled="!(isAdministrator || isSupervisior)"
        @back="handleDiscard"
        @save="save"
    />

    <eav-modal ref="eavModal" @submit="handleEAVSubmit"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'
import EavModal from '../../components/modals/EavModal.vue'
import ShopsTable from '../../components/tables/ShopsTable.vue'
import WarehousesTable from '../../components/tables/WarehousesTable.vue'
import SendersTable from '../../components/tables/SendersTable.vue'
import AccountsTable from '../../components/tables/AccountsTable.vue'
import LegalEntitiesTable from '../../components/tables/LegalEntitiesTable.vue'
import ConnectionsTable from '../../components/tables/ConnectionsTable.vue'

export default {
  name: 'User',
  components: {
    ConnectionsTable,
    ActionHeader,
    EavModal,
    ShopsTable,
    WarehousesTable,
    SendersTable,
    AccountsTable,
    LegalEntitiesTable
  },
  data() {
    return {
      isSaveLoading: false,
      hasChange: false,
      adapters: [
        { id: '2', name: 'Header Authentication' },
        { id: '3', name: 'Http' },
        { id: '4', name: 'Request' },
        { id: '5', name: 'Terminal' },
        { id: '6', name: 'User' }
      ],
      lockedFields: [
        'restrict-parallel-login',
        'allow-parallel-login'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'user',
      'users',
      'isAdministrator',
      'isSupervisior'
    ]),
    options() {
      if (!this.user || !this.user.id) {
        return {}
      }

      return {
        addButton: this.isAdministrator || this.isSupervisior,
        filters: [
          { type: 'eq', field: 'owner', value: this.user.id }
        ],
        style: {
          header: 'bg-blue-grey-1 border-bottom border--teal-1'
        }
      }
    },
    passwordError() {
      if (!this.user) {
        return ''
      }

      if (!this.user.password) {
        return ''
      }

      if (this.user.password.length < 9) {
        return 'Use at least 9 characters.'
      }

      if (!this.user.password.match(/[A-Z]/) || !this.user.password.match(/[a-z]/)) {
        return 'Use both low and upper case characters.'
      }

      if (!this.user.password.match(/\d+/)) {
        return 'Use one or more numbers.'
      }

      return ''
    },
    schema() {
      const fields = [
        {
          type: 'input',
          inputType: 'text',
          label: this.$t('Name'),
          field: 'name',
          value: this.user && this.user.name,
          wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
          onChange: name => {
            this.hasChange = true
            this.updateUser({ name })
          }
        },
        {
          type: 'input',
          inputType: 'text',
          label: this.$t('Username'),
          field: 'username',
          value: this.user && this.user.username,
          wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
          onChange: username => {
            this.hasChange = true
            this.updateUser({ username })
          }
        },
        {
          type: 'input',
          inputType: 'email',
          label: this.$t('Email'),
          field: 'email',
          value: this.user && this.user.email,
          wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
          onChange: email => {
            this.hasChange = true
            this.updateUser({ email })
          }
        },
        {
          type: 'password',
          inputType: 'password',
          label: this.$t('Password'),
          field: 'password',
          value: this.user && this.user.password,
          wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
          autocomplete: 'new-password',
          autoShow: 3000,
          hint: this.passwordError,
          details: [
            this.$t('Use at least 9 characters.'),
            this.$t('Use both low and upper case characters.'),
            this.$t('Use one or more numbers.'),
            this.$t('Optionally use special characters.')
          ],
          onInput: password => {
            this.hasChange = true
            this.updateUser({ password })
          }
        },
        {
          type: 'input',
          inputType: 'text',
          label: this.$t('Client Id'),
          field: 'eav.application-user-id',
          value: this.user && this.user.eav['application-user-id'],
          wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
          disabled: !this.isAdministrator,
          onChange: value => {
            this.hasChange = true
            this.updateUser({
              eav: {
                ...this.user.eav,
                'application-user-id': value
              }
            })
          }
        },
        {
          type: 'tag',
          label: this.$t('Domains'),
          wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
          field: 'domains',
          disabled: !this.isAdministrator,
          value: this.user && this.user.domains,
          onScroll: (search, page) => {
            const query = {
              per_page: 25,
              page,
              search
            }

            return this.$service.domain.getAll(query)
          },
          customTag: (item) => {
            if (item && typeof item === 'object') {
              return this.$t(item.name)
            }

            return item
          },
          customListItem: (item) => {
            if (item && typeof item === 'object') {
              return this.$t(item.name)
            }

            return item
          },
          onRemove: (val) => {
            this.hasChange = true
            this.updateUser({
              domains: this.user.domains.filter(x => x.id !== val.id)
            })
          },
          onAdd: (value) => {
            if (Array.isArray(value)) {
              return
            }

            this.hasChange = true
            let hasValue = false

            const domains = this.user.domains.filter(val => {
              if (val.id === value.id) {
                hasValue = true
                return false
              }

              return true
            })

            if (hasValue) {
              this.updateUser({ domains })
              return
            }

            this.updateUser({ domains: [...this.user.domains, value] })
          }
        },
        {
          type: 'multiselect',
          label: this.$t('Domain'),
          field: 'domain',
          value: this.user && this.user.domain,
          wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
          disabled: !this.isAdministrator,
          customLabel(row) {
            if (row && typeof row === 'object') {
              return row.name
            }

            return row
          },
          onScroll: (search, page) => {
            const query = {
              per_page: 25,
              page,
              search,
              filter: []
            }

            return this.$service.domain.getAll(query)
          },
          onChange: (value) => {
            this.hasChange = true
            this.updateUser({ domain: value.extId })
          }
        },
        {
          type: 'tag',
          label: this.$t('Roles'),
          wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
          field: 'roles',
          disabled: !this.isAdministrator,
          value: this.user && this.user.roles,
          onScroll: (search, page) => {
            const query = {
              per_page: 25,
              page,
              search
            }

            return this.$service.userRoles.getAll(query)
          },
          customTag: (item) => {
            return this.$t(item.name || item.roleName)
          },
          customListItem: (item) => {
            return this.$t(item.name || item.roleName)
          },
          onRemove: (val) => {
            this.hasChange = true
            this.updateUser({
              roles: this.user.roles.filter(x => x.id !== val.id)
            })
          },
          onAdd: (value) => {
            if (Array.isArray(value)) {
              return
            }

            this.hasChange = true
            let hasValue = false

            const roles = this.user.roles.filter(val => {
              if (val.id === value.id) {
                hasValue = true
                return false
              }

              return true
            })

            if (hasValue) {
              this.updateUser({ roles })
              return
            }

            this.updateUser({ roles: [...this.user.roles, { id: value.id, name: value.roleName }] })
          }
        },
        {
          type: 'tag',
          label: this.$t('Adapters'),
          wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
          field: 'allowedAdapters',
          value: this.user && this.user.allowedAdapters,
          options: this.adapters,
          disabled: !this.isAdministrator,
          customListItem: (item) => {
            if (item && typeof item === 'object') {
              return this.$t(item.name)
            }

            return item
          },
          customTag: (item) => {
            if (!item) {
              return item
            }

            const adapter = this.adapters.find(({ id }) => id === item) || { name: item }
            return this.$t(adapter.name)
          },
          onRemove: (val) => {
            this.hasChange = true
            this.updateUser({
              allowedAdapters: this.user.allowedAdapters.filter(id => id !== val)
            })
          },
          onChanged: (value) => {
            if (Array.isArray(value)) {
              return
            }

            this.hasChange = true
            let isExist = false

            const allowedAdapters = this.user.allowedAdapters.filter(val => {
              if (val === value.id) {
                isExist = true
                return false
              }

              return true
            })

            if (isExist) {
              this.updateUser({ allowedAdapters })
              return
            }

            this.updateUser({
              allowedAdapters: [...this.user.allowedAdapters, value.id]
            })
          }
        },
        {
          type: 'input',
          inputType: 'number',
          label: this.$t('Parallel logins'),
          field: 'eav.allow-parallel-login',
          value: this.user && this.user.eav['allow-parallel-login'],
          wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
          disabled: !this.isAdministrator,
          onChange: value => {
            this.hasChange = true
            this.updateUser({
              eav: {
                ...this.user.eav,
                'allow-parallel-login': value
              }
            })
          }
        }
      ]

      return {
        isLoading: !this.user,
        groups: [
          {
            styleClasses: 'row',
            fields: fields.map(field => {
              field.disabled = field.disabled || !(this.isAdministrator || this.isSupervisior)
              return field
            })
          }
        ]
      }
    },
    page() {
      return {
        id: this.user && this.user.id,
        name: this.user && this.user.id
            ? this.$t('User')
            : this.$t('New user')
      }
    },
    headerModel() {
      if (!this.user) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'q-px-xs',
              valueStyle: 'white-space: nowrap',
              type: 'text',
              value: this.user.id
                  ? this.$t('User ID: ') + this.user.id
                  : this.$t('New User')
            }
          ]
        },
        {
          section: 'auth',
          className: 'col-12 col-md row justify-end',
          options: [
            {
              if: this.isSupervisior || this.isAdministrator,
              id: 'auth',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              icon: 'login',
              color: 'negative',
              label: this.$t('Authorization'),
              onClick: () => {
                return this.$service.user.getAuthentication(this.user.id)
                    .then(userdata => {
                      this.$eventBus.update('re-login', { user: userdata })
                    })
              }
            },
            {
              if: this.isSupervisior || this.isAdministrator,
              id: 'badge',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              icon: 'print',
              color: 'positive',
              label: this.$t('Badge'),
              onClick: () => {
                return this.$service.user.getPDF(this.user.id)
                    .then(badge => {
                      return this.$service.printer.print(badge)
                    })
              }
            },
            {
              if: false,
              id: 'eav',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              label: this.$t('EAV'),
              hasIcon: true,
              variant: 'light',
              onClick: () => {
                this.$refs.eavModal.open(this.shop.eav, { entityClass: this.$entities.Orderadmin_Users_Entity_User })
              }
            }
          ]
        }
      ]
    },
    isEmployee() {
      return this.user?.type === 'employee'
    }
  },
  mounted() {
    if (!this.$route.params.id) {
      this.setNewUser({ type: this.$route.query.type })

      if (this.$route.query.type === 'employee') {
        const adapter = this.adapters.map(e => e.id)
        this.updateUser({
          allowedAdapters: adapter,
          discr: 'employee',
        })
      }
    }

    if (this.$route.params.id && !this.user) {
      this.loadUser(this.$route.params.id)
    }
  },
  unmounted() {
    this.setUser(null)
  },
  methods: {
    ...mapActions([
      'loadUser',
      'saveUser'
    ]),
    ...mapMutations([
      'setUser',
      'setNewUser',
      'updateUser',
      'upsertUser',
      'addErrorNotification',
      'setCurrentUser'
    ]),
    handleEAVSubmit(eav) {
      this.updateUser({ eav })
      this.hasChange = true
    },
    createFields(strModel, obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] && typeof obj[key] === 'object' && !obj[key].id) {
          const fields = this.createFields(`${strModel}${strModel ? '.' : ''}${key}`, obj[key])
          return [...acc, ...fields]
        }

        const label = key.replaceAll('-', ' ')

        acc.push({
          type: 'input',
          inputType: 'text',
          label: this.$t(`Eav -> ${label[0].toUpperCase()}${label.slice(1).toLowerCase()}`),
          field: `${strModel || ''}${strModel ? '.' : ''}${key}`,
          wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
          featured: true,
          disabled: this.lockedFields.includes(key),
          onChanged: model => {
            this.hasChange = true
            this.updateUser(model)
          }
        })

        return acc
      }, [])
    },
    refresh() {
      this.loadUser(this.$route.params.id)
    },
    save() {
      if (this.passwordError) {
        this.addErrorNotification('The password you entered doesn\'t meet the minimum security requirements!')
        return
      }

      this.isSaveLoading = true
      return this.saveUser()
          .then(user => {
            this.hasChange = false
            this.upsertUser(user)
            if (!this.user.id) {
              this.handleBack()
            }
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    handleDiscard() {
      this.$router.go()
    },
    handleBack() {
      this.$router.back()
    }
  }
}
</script>
