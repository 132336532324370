<template>
  <div>
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend :label="$t('Accounts')"/>

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          v-if="!hideActions"
          :color="filters && filters.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="toggleFilters"
        />

        <q-btn
          v-if="!hideActions"
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          v-if="!hideActions || options.addButton"
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: filters,
          fields: activatedFields,
          values: {
            states: statuses,
            types: types
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="toggleFilters"
      />

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          :filter="filter"
          :table-header-class="$q.dark.isActive ? '' : (options.style && options.style.header) || ''"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                auto-width
              >
                {{ props.row.id }}
              </q-td>

              <q-td
                key="name"
                :props="props"
                auto-width
              >
                <q-badge
                  :color="stateColors[props.row.state]"
                  class="q-mr-sm"
                  rounded
                />

                {{ props.row.name }}
              </q-td>

              <q-td
                key="type"
                :props="props"
                auto-width
              >
                <q-badge
                  :color="typeColors[props.row.type]"
                  :label="props.row.type"
                  class="q-px-sm q-py-xs"
                  rounded
                />
              </q-td>

              <q-td
                key="balance"
                :props="props"
                auto-width
              >
                {{ props.row.balance }}
              </q-td>

              <q-td
                key="accountRate"
                :props="props"
                auto-width
              >
                <span v-if="props.row._embedded.rate">
                  {{ `${props.row._embedded.rate.name} (${props.row._embedded.rate.id})` }}
                </span>
              </q-td>

              <q-td
                key="owner"
                :props="props"
                auto-width
              >
                <span v-if="props.row._embedded.owner">
                  {{ `${props.row._embedded.owner.name} (${props.row._embedded.owner.id})` }}
                </span>
              </q-td>

              <q-td
                key="customer"
                :props="props"
                auto-width
              >
                <span v-if="props.row._embedded.customer">
                  {{ `${props.row._embedded.customer.name} (${props.row._embedded.customer.id})` }}
                </span>
              </q-td>

              <q-td
                key="contractor"
                :props="props"
                auto-width
              >
                <span v-if="props.row._embedded.contractor">
                  {{ `${props.row._embedded.contractor.name} (${props.row._embedded.contractor.id})` }}
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
                auto-width
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <q-dialog v-model="accountModal">
      <q-card>
        <q-card-section>
          <account
            @close="handleAccountModal"
            @save="handleAccount"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import Search from '../../components/search/Search.vue'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Account from '../../components/account/Account.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'AccountsTable',
  emits: ['account'],
  components: {
    Search,
    FilterCollapse,
    Account
  },
  props: {
    options: {
      type: Object,
      default () {
        return {
          filters: []
        }
      }
    },
    hideActions: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      accountModal: false,
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Balance'),
          name: 'balance',
          align: 'left'
        },
        {
          label: this.$t('Billing'),
          name: 'accountRate',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Customer'),
          name: 'customer',
          align: 'left'
        },
        {
          label: this.$t('Contractor'),
          name: 'contractor',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        }
      ],
      typeColors: {
        deposit: 'teal-6',
        payment: 'amber-5 text-dark'
      },
      stateColors: {
        active: 'active',
        frozen: 'grey',
        inactive: 'inactive'
      },
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'frozen', title: 'Frozen' },
        { id: 'inactive', title: 'Inactive' }
      ],
      types: [
        { id: 'deposit', title: 'Deposit' },
        { id: 'payment', title: 'Payment' }
      ],
      activatedFields: [
        'id',
        'state',
        'created.from',
        'created.to',
        'name',
        'owner',
        'type',
        'balance.from',
        'balance.to'
      ],
      items: [],
      isLoading: false,
      filters: [
        { type: 'in', field: 'state', values: ['active', 'frozen', 'blocked'] }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    filterBtnText () {
      return this.$t('Filter')
    }
  },
  mounted () {
    if (this.options && this.options.filters) {
      this.filters = [...this.options.filters, ...this.filters]
    }

    this.loadDefaultItems()
  },
  methods: {
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    upsertItem (item) {
      let isExist = false

      this.items = this.items.map(x => {
        if (x.id === item.id) {
          isExist = true
          return item
        }

        return x
      })

      if (!isExist) {
        this.items.unshift(item)
      }

      return this.items
    },
    create () {
      this.accountModal = true
    },
    handleAccountModal () {
      this.accountModal = false
    },
    handleAccount (account) {
      this.upsertItem(account)
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      this.filters = filter
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: { page: 1, rowsPerPage: 5 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters
      this.isLoading = true

      return this.$service.accounts.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.isLoading = false
          this.items = items

          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRowDblClick (account) {
      this.$router.push({ path: '/billing/accounts', query: { page: 1, per_page: 25, forceOpen: true, filter: [{ type: 'eq', field: 'id', value: account.id }] } })
    }
  }
}
</script>
