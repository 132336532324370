<template>
  <q-card>
    <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
      <q-legend :label="$t('Integrations')" />

      <search
        dense
        autoset
        is-expandable
        @submit="handleSearch"
      />

      <q-space />

      <q-btn
        v-if="!hideActions"
        :color="filters.length > 0 ? 'light-blue-9' : 'dark'"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="filterBtnText"
        no-caps
        unelevated
        @click="toggleFilter"
      />

      <q-btn
        v-if="!hideActions"
        color="dark"
        text-color="white"
        :label="$t('Refresh')"
        size="sm"
        class="q-mr-sm"
        no-caps
        unelevated
        @click="refreshItems"
      />

<!--      <q-btn-->
<!--        v-if="!hideActions || options.addButton"-->
<!--        color="light-blue-9"-->
<!--        text-color="white"-->
<!--        icon="add"-->
<!--        size="sm"-->
<!--        no-caps-->
<!--        unelevated-->
<!--        @click="create"-->
<!--      />-->
    </q-card-section>

    <filter-collapse
      :is-open="isOpen"
      :options="{
        defaultFilter: filters,
        fields: activatedFields,
        values: {
          states: statuses
        },
        style: {
          noGroups: true
      }
    }"
      @submit="handleFiltersSubmit"
      @close="toggleFilter"
    />

    <q-card-section class="q-ma-none q-pa-none">
      <q-table
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="isLoading"
        :filter="filter"
        :table-header-class="$q.dark.isActive ? '' : (options.style && options.style.header) || ''"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
            showing
            color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
            :props="props"
            class="clickable"
          >
            <q-td
              key="id"
              :props="props"
              class="text-subtitle1"
            >
                <strong>{{ props.row.id }}</strong>
            </q-td>

            <q-td
              key="name"
              :props="props"
            >
                {{ props.row.name }}
            </q-td>



            <q-td
              key="created"
              :props="props"
            >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
            </q-td>

            <q-td
              key="state"
              :props="props"
            >
              <q-badge
                v-if="isClient"
                :color="stateColors[props.row.state]"
                :label="$t(props.row.state)"
              />

              <q-toggle
                v-else
                color="light-blue-9"
                :model-value="props.row.state === 'active'"
                @update:model-value="handleStateChange(props.row)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>

      <q-dialog v-model="legalEntityModal">
        <q-card>
          <q-card-section>
            <legal-entity
              :id="(legalEntity || {}).id"
              @submit="upsertItem"
              @close="handleLegalEntityModal"
            />
          </q-card-section>
        </q-card>
      </q-dialog>
  </q-card>

</template>

<script>
// Vuex
import { mapGetters, mapActions , mapMutations } from 'vuex'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'
import LegalEntity from '../legal-entities/LegalEntity.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'
import SourceModal from "../../components/integration-wizard/SourceModal.vue";

export default {
  name: 'ConnectionsTable',
  components: {
      SourceModal,
    Search,
    FilterCollapse,
    LegalEntity
  },
  props: {
    options: {
      type: Object,
      default () {
        return {
          filters: []
        }
      }
    },
    hideActions: {
      type: Boolean,
      default () {
        return false
      }
    },
    user: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      legalEntityModal: false,
      isLoading: false,
      fallbackImage: 'assets/img/fallback-image/package.png',
      items: [],
      stateColors: {
          active: 'teal',
          normal: 'success',
          blocked: 'dark'
      },
      isOpen: false,
      filter: '',
      pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
          rowsNumber: 5
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'id',
        'name',
        'owner',
        'created.from',
        'created.to',
        'state'
      ],
      filters: [
        { type: 'eq', field: 'state', value: 'active' }
      ]
    }
  },
  computed: {
      ...mapGetters([
          'isClient',
          'appOptions',
          'legalEntity'
      ]),
      filterBtnText () {
          return this.$t('Filter')
      }
  },
  mounted () {
      if (this.options && this.options.filters) {
          this.filters = [...this.options.filters, ...this.filters]
      }

      this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
        'loadIntegrations'
    ]),
    ...mapMutations([
        'setLegalEntity',
    ]),
    refreshItems () {
        return this.onRequest({
            pagination: {
                forceReload: true
            }
        })
    },
    handleLegalEntityModal () {
        this.legalEntityModal = !this.legalEntityModal
    },
    upsertItem (item) {
        let isExist = false

        this.items = this.items.map(x => {
            if (x.id === item.id) {
                isExist = true
                return item
            }

            return x
        })

        if (!isExist) {
            this.items.unshift(item)
        }

        return this.items
    },
    handleStateChange (item) {
        const invertedState = {
            active: 'inactive',
            inactive: 'active'
        }

        const state = invertedState[item.state]

        return this.$service.iSource.save({ state }, item.id)
            .then(item => {
                this.upsertItem(item)
            })
    },
    handleFiltersSubmit (filter) {
        this.isOpen = false
        this.filters = filter
        return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilter () {
        this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
        return this.onRequest({ pagination: { page: 1, rowsPerPage: 5 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters
      if(!query.filter) {
        query.filter = [
          {
            type:'eq',
            field:'owner',
            value: this.user.id,
          }]
      } else {
        query.filter.push({
          type:'eq',
          field:'owner',
          value: this.user.id,
        })
      }

      this.isLoading = true
      return this.$service.iSource.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
          }

          this.isLoading = false
          this.items = items

          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    create () {
      this.legalEntityModal = true
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRowDblClick (row) {
      this.setLegalEntity(row)
      this.legalEntityModal = true
    }
  }
}
</script>
